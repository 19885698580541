import React, { useState } from "react";

import DefaultLayout from "../../../../layouts/default";

const DocLMobilUnterwegs = () => {
  const [imageSwitch1, setImageSwitch1] = useState(
    "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA.png"
  );
  const [imageSwitch2, setImageSwitch2] = useState(
    "/assets/images/screenshots/l-besa/neu/Kalender_pers.png"
  );

  const [imageSwitch3, setImageSwitch3] = useState(
    "/assets/images/screenshots/l-besa/neu/Medirichten_korrekt.png"
  );

  const [akkordeonOpen, setAkkordeonOpen] = useState(false);

  return (
    <DefaultLayout title="mobil unterwegs – WiCare|Doc-L">
      <div className="white wicare vanadium">
        <div className="navContentSpacerHelper"></div>
        <header className="navWrapper">
          <nav className="mainNav">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="subNav2">
            <div className="subNav2Product">
              <div className="productImage">
                <img
                  src="/assets/images/logos/WCN-Doc.svg"
                  alt="WiCare|Doc Logo"
                  width="40"
                />
              </div>
              <div className="productText">
                <span className="line">WiCare|Doc-L</span>
                <span className="line">
                  <span className="is-visible-desktop">
                    &nbsp;für Langzeit-Institutionen
                  </span>
                  &nbsp;nach RAI-NH
                </span>
              </div>
            </div>
            <div className="subNav2-opener"></div>
            <ul className="subNav2Links">
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                  Übersicht
                </a>
              </li>
              <li className="is-active">
                <a href="/dokumentationsloesungen/wicare-doc-l/mobile/index_rai">
                  Mobile
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-l/technik/index_rai">
                  Technik
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-l/referenzen/index_rai">
                  Referenzen
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-doc-l/downloads/index_rai">
                  Downloads
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <section className="mainBanner bannerWeiss">
            <div className="wrapper has-padding-top has-padding-bottom">
              <div className="bannerFlex">
                <div>
                  <img
                    className="logo-product"
                    src="/assets/images/logos/WCN-Doc.svg"
                  />
                </div>
                <div className="bannerPadding">
                  <h1>WiCare|now Doc</h1>
                  <h3 className="padding-25-bottom">mobil unterwegs</h3>
                  <p className=" bannerText">
                    WiCare|now Doc lässt Pflege dort dokumentieren, wo sie
                    anfällt, um eine auf den Menschen ausgerichtete individuelle
                    Pflege und Behandlung zu planen, zu dokumentieren und zu
                    beurteilen.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="wrapper">
            <section>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Im Jahr 2006 startete WigaSoft mit der Entwicklung der
                elektronischen Pflegedokumentation WiCare|Doc. Wir orientieren
                uns stark am Pflegeprozess und unterstützen somit eine
                professionelle Pflege vom Eintritt bis zum Austritt.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Der technische Wandel führte vor einigen Jahren zum
                strategischen Entscheid, WiCare|Doc webbasiert neu zu
                entwickeln. Nebst einer qualitativ hochwertigen
                Pflegedokumentation, ermöglicht die webbasierte Lösung
                WiCare|now Doc bei Bedarf das mobile Arbeiten. Durch die
                Flexibilität der Applikation können betriebliche Prozesse
                schlanker und effizienter gestaltet werdenn. Die eingesparten
                Ressourcen können somit zum Wohle der Bewohner*Innen eingesetzt
                werden.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Mit neuen Technologien ergeben sich neue Möglichkeiten. Die
                WigaSoft AG setzt diesen technischen Fortschritt gezielt ein, um
                Ihren Produkten noch viel mehr Nutzen für die Anwender zu
                ermöglichen.{" "}
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                WiCare|now Doc ist eine komplette Neuentwicklung der etablierten
                Pflegedokumentation WiCareDoc.{" "}
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Neben der neuen Web-Technologie setzten wir altbewährte
                Funktionen noch effizienter im neuen Produkt um, ergänzt mit
                vielen neuen Funktionen und Möglichkeiten.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                WiCare|now Doc steht auf PC/Laptop, Tablet und teilweise auch
                auf dem Smartphone zur Verfügung. Somit kann das Dokumentieren
                flexibel und effizienter gestaltet werden, was der heuteigen
                Digitalisierung der Arbeitswelt gerecht wird und schlussendlich
                allen beteiligten zugutekommt.{" "}
              </p>
              <p className="fliesstextLead text-centered-td">
                Die WigaSoft AG setzt auf eine modulare Ablösung von WiCare Doc
                durch WiCare|now Doc. Es werden bestehende Module in WiCareDoc,
                z.B. der Verlaufsbericht durch neue WiCare|now Doc Komponente,
                abgelöst. Diese Schritt für Schritt Ablösung ermöglicht unseren
                Kunden einen raschen Einsatz von den Vorteilen die diese mit
                sich bringen. Schlussendlich wird die ganze Software migriert.
              </p>
            </section>
          </div>
          <div className="wrapper mobile-tablet-no-padding">
            <section className="cContainer is-background-color-blue is-color-white rounded">
              <h4 className="smallStaticPadding-bottom text-centered-td h4Bold">
                Best Features
              </h4>
              <div className="ccHalfFlex">
                <div>
                  <ul className="featuresList">
                    <li>Webbasierte, plattformunabhängige Software-Lösung</li>
                    <li>
                      mobiles Arbeiten ermöglicht innerbetriebliche
                      Prozessoptimierungen
                    </li>
                    <li>
                      Drittapplikationen, wie bspw. Intranet, Compendium oder
                      andere Tools, lassen sich einfach via URL einbetten.
                    </li>
                    <li>
                      Ebenso lässt sich WiCare|now Doc mittels URL in
                      Drittapplikationen integrieren
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="featuresList">
                    <li>
                      Zugriffe auf Funktionalitäten im Benutzer-,
                      Bewohnergruppen- und Bewohnerkontext individuell
                      konfigurierbar
                    </li>
                    <li>
                      Lese- und Schreibrechte können rollenspezifisch definiert
                      werden
                    </li>
                    <li>
                      Unser Produktportfolio (WiCare|now LE, LEP, tacs) ist in
                      einer Applikation vereint und lässt sich beliebig
                      kombinieren
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          {/*https://wigasoft-prod.imgix.net/screenshots....?auto=format,compress*/}
          <div className="wrapper wrapper-xl">
            <section className="margin-45-bottom">
              <div
                className={
                  akkordeonOpen
                    ? "funktionenToggle is-background-color-blue dynamic-padding-bottom selected"
                    : "funktionenToggle is-background-color-blue dynamic-padding-bottom"
                }
                onClick={() => setAkkordeonOpen(!akkordeonOpen)}
              >
                <h3>Funktionen</h3>
                <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
              </div>
              <div>
                <article className="mobilUnterwegs has-padding-top">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Bewohnerinformation.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Bewohnerinformation"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Bewohnerinformation.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Bewohnerinformation</h4>
                    <p className="fliesstext">
                      Übersicht aller Informationen zum Bewohner. Die meisten
                      unserer Kunden profitieren von einer Schnittstelle zum
                      ERP-System. Dies ermöglicht eine automatische Übertragung
                      der Stammdaten ins WiCare|now Doc, welche somit auf allen
                      Geräten in einer leserfreundlichen Ansicht zur Verfügung
                      stehen. Wenn die Stammdaten mit einem Foto ergänzt werden
                      sollen, so kann dies ganz einfach via Tablet- oder
                      Handykamera aufgenommen und direkt hinzugefügt werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Cockpit.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Cockpit"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Cockpit.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Cockpit</h4>
                    <p className="fliesstext">
                      Das Cockpit verschafft einen schnellen
                      Informationsüberblick und bündelt wichtige
                      Dokumentationsinhalte für spezifische Aufgaben wie z.B.
                      das Einlesen bei Schichtantritt oder auch als Grundlage
                      für eine interdisziplinäre Fallbesprechung. WigaSoft hat
                      über die Jahre die Bedürfnisse der Kunden analysiert und
                      stellt ein standardisiertes Cockpit zur Verfügung. Sollte
                      der Betrieb individuelle Anpassungen benötigen, so wird
                      deren Umsetzung im Rahmen der Projektumsetzung geprüft.
                      Durch die Cockpits können sich die verschiedenen
                      Berufsgruppen rasch einen Überblick über relevante
                      Informationen verschaffen, was Ihnen den Arbeitsalltag
                      erleichtert.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Verlaufsbericht.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Verlaufsbericht"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Verlaufsbericht.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Verlaufsbericht</h4>
                    <p className="fliesstext">
                      Im Verlaufsbericht werden alle Beobachtungen zum aktuellen
                      Befinden der Bewohner und zur Wirksamkeit der
                      durchgeführten Massnahmen dokumentiert. Es können
                      Verlaufsberichtstypen wie Pflegeberichte, Visite, o.ä.
                      hinterlegt werden. Zudem können die Einträge kategorisiert
                      werden. Dies erleichtert zu einem späteren Zeitpunkt,
                      bspw. im Rahmen einer Zielevaluation oder einer
                      Beobachtungsphase «BESA/RAI», die Nutzung der Daten, indem
                      man diese themenspezifisch filtern kann.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href={imageSwitch3}
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Medikamente richten und kontrollieren"
                    >
                      <img src={imageSwitch3} />
                    </a>
                    <div className="mehrBilder">
                      <div
                        onMouseEnter={() =>
                          setImageSwitch3(
                            "/assets/images/screenshots/l-besa/neu/Medirichten_korrekt.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Medirichten_korrekt.png"
                          data-lightbox={
                            imageSwitch3 ===
                            "/assets/images/screenshots/l-besa/neu/Medirichten_korrekt.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Medikamente richten und kontrollieren"
                          className="deactivated"
                        >
                          1
                        </a>
                      </div>
                      <div
                        onMouseEnter={() =>
                          setImageSwitch3(
                            "/assets/images/screenshots/l-besa/neu/Medi_Compendium.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Medi_Compendium.png"
                          data-lightbox={
                            imageSwitch3 ===
                            "/assets/images/screenshots/l-besa/neu/Medi_Compendium.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Medikamente richten und kontrollieren"
                          className="deactivated"
                        >
                          2
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">
                      Medikamente richten und kontrollieren
                    </h4>
                    <p className="fliesstext">
                      Unter Berücksichtigung von Kundenfeedback hat WigaSoft
                      explizit für das Richten und Kontrollieren von
                      Medikamenten eine spezifische Ansicht erstellt. Diese
                      Komponente zeichnet sich dadurch aus, dass die angewählte
                      Zeile farbig markiert hervorsticht. Somit weiss die
                      Pflegeperson immer, wo sie sich im Prozess gerade befindet
                      und das Fehlerrisiko wird minimiert. Das Richten, wie auch
                      das Kontrollieren der Medikamente, kann bei Bedarf bis auf
                      die Ebene der Verabreichungszeit dokumentiert werden.
                      Durch eine Kommentarfunktion kann zudem bspw. bei
                      Nichtvorhandensein eines Medikamentes eine Begründung
                      erfasst werden. Die anschliessende Visierung durch den
                      User gewährleistet Transparenz und Sicherheit. Die
                      angezeigten Medikamente verfügen über einen Weblink, der
                      direkt zu den pharmazeutischen Fachinformationen im{" "}
                      <a href="https://compendium.ch/" target="_blank">
                        Compendium
                      </a>{" "}
                      führt.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Vitalwerte.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Vitalwerte"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Vitalwerte.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Vitalwerte</h4>
                    <p className="fliesstext">
                      Es werden individuelle Mess-Sets zur Verfügung gestellt.
                      Die Resultate sind in einer Tabelle oder in Form einer
                      Kurvengrafik abrufbar. Die Werte lassen sich nach Datum
                      oder nach Messwert sortieren. Dadurch sind Veränderungen
                      innerhalb einer Messreihe schnell erkennbar. Der jeweilige
                      Höchstwert, Tiefstwert und letzte gemessene Wert, ist auf
                      jedem Vitalwert erkennbar, somit kann der gemessene Wert
                      auch in Relation zur Gesamtsituation gesetzt werden.
                      Überschreitet ein Vitalwert einen definierten Grenzwert,
                      wird der Nutzer durch eine Warnmeldung darauf hingewiesen.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href={imageSwitch1}
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Fokus-Assessments und Scores"
                    >
                      <img src={imageSwitch1} />
                    </a>
                    <div className="mehrBilder">
                      <div
                        onMouseEnter={() =>
                          setImageSwitch1(
                            "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Schmerz_ECPA.png"
                          data-lightbox={
                            imageSwitch1 ===
                            "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Fokus-Assessments und Scores"
                          className="deactivated"
                        >
                          1
                        </a>
                      </div>
                      <div
                        onMouseEnter={() =>
                          setImageSwitch1(
                            "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA_Verlauf.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Schmerz_ECPA_Verlauf.png"
                          data-lightbox={
                            imageSwitch1 ===
                            "/assets/images/screenshots/l-besa/neu/Schmerz_ECPA_Verlauf.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Fokus-Assessments und Scores"
                          className="deactivated"
                        >
                          2
                        </a>
                      </div>
                      <div
                        onMouseEnter={() =>
                          setImageSwitch1(
                            "/assets/images/screenshots/l-besa/neu/Schmerz_EPCA_Grafik.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Schmerz_EPCA_Grafik.png"
                          data-lightbox={
                            imageSwitch1 ===
                            "/assets/images/screenshots/l-besa/neu/Schmerz_EPCA_Grafik.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Fokus-Assessments und Scores"
                          className="deactivated"
                        >
                          3
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Fokus-Assessments und Scores</h4>
                    <p className="fliesstext">
                      In WiCare|now Doc stehen diverse Fokus-Assessments und
                      Scores zur Verfügung. Es steht eine Verlaufsübersicht zur
                      Verfügung, um die Entwicklung übersichtlich nachvollziehen
                      zu können. Sofern es sich um einen Score handelt, lässt
                      bei Bedarf ein grafischer Verlauf anzeigen. Durch die
                      mobile Verfügbarkeit der Erfassungsinstrumente kann bspw.
                      ein ECPA direkt am Bewohnerbett erfasst werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Wunddokumentation.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Wunddokumentation"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Wunddokumentation.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Wunddokumentation</h4>
                    <p className="fliesstext">
                      In der Wunddokumentation haben Sie die Möglichkeit,
                      verschiedene Wunden zu erfassen und deren Behandlung und
                      Verlauf zu dokumentieren. Nebst vorgefertigten
                      Erfassungsmasken für Wundarten, wie bspw. Dekubitus, Ulcus
                      oder Verbrennungen, kann diese Komponente ebenso für die
                      Stomadokumentation verwendet werden. Die Vorlagen können
                      dem Wundkonzept Ihrer Institution angepasst werden. Weiter
                      erlaubt Ihnen die mobile Nutzung von WiCare|now Doc, mit
                      dem Tablet/Phone ein Wundfoto aufzunehmen und direkt in
                      der Wunddokumentation abzulegen. Beim Erfassen eines
                      Dekubitus wird eine Statistik geführt. Dies ermöglicht der
                      qualitätsverantwortlichen Person innerbetriebliche
                      Auswertungen.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href={imageSwitch2}
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Kalender*"
                    >
                      <img src={imageSwitch2} />
                    </a>
                    <div className="mehrBilder">
                      <div
                        onMouseEnter={() =>
                          setImageSwitch2(
                            "/assets/images/screenshots/l-besa/neu/Kalender_pers.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Kalender_pers.png"
                          data-lightbox={
                            imageSwitch2 ===
                            "/assets/images/screenshots/l-besa/neu/Kalender_pers.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Kalender*"
                          className="deactivated"
                        >
                          1
                        </a>
                      </div>
                      <div
                        onMouseEnter={() =>
                          setImageSwitch2(
                            "/assets/images/screenshots/l-besa/neu/Kalender_Station.png"
                          )
                        }
                      >
                        <a
                          href="/assets/images/screenshots/l-besa/neu/Kalender_Station.png"
                          data-lightbox={
                            imageSwitch2 ===
                            "/assets/images/screenshots/l-besa/neu/Kalender_Station.png"
                              ? ""
                              : "l-besa-1"
                          }
                          data-title=""
                          data-actual-title="Kalender*"
                          className="deactivated"
                        >
                          2
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">
                      Kalender<a href="#zusatz">*</a>
                    </h4>
                    <p className="fliesstext">
                      Der WiCare|now Kalender unterstützt Sie auf diversen
                      Ebenen. Einem Termin können Mitarbeitende, Bewohner,
                      Stationen und Ressourcen zugewiesen werden.
                      Dementsprechend können die einzelnen Ebenen auch separat
                      aufgerufen werden. Dies erleichtert die betriebliche
                      Organisation enorm.
                      <br />
                      <br />
                      Weiter besteht ein Terminassistent. Möchte man zum
                      Beispiel einen Termin mit mehreren Bewohnern planen, so
                      erscheint bei einer Terminkollision eine Meldung. Der
                      Terminassistent kann geöffnet und der Termin entsprechend
                      angepasst werden.
                      <br />
                      <br />
                      Der Kalender ist dem Personal zudem eine grosse Hilfe bei
                      der Tagesübersicht. Dank der Terminliste lässt sich für
                      einen definierten Zeitraum eine Übersicht erstellen, die
                      entweder nach Bewohner oder nach Zeit sortiert werden
                      kann. Diese Liste lässt sich bei Bedarf ausdrucken. Ein
                      vergleichbares Format besteht auch auf Bewohnerebene.
                      Somit könnte bspw. eine Wochenübersicht im Bewohnerzimmer
                      angebracht werden. Dies dient der Tagesstruktur und
                      verschafft Orientierung. Sollte es Termine geben, die
                      wöchentlich zur gleichen Zeit stattfinden, so kann ein
                      Serientermin erstellt werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Sachverlauf_HiMi.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Sachverlauf"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Sachverlauf_HiMi.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">
                      Sachverlauf<a href="#zusatz">*</a>
                    </h4>
                    <p className="fliesstext">
                      Der Sachverlauf ermöglicht es, einen Verlauf, z.B. zu
                      einem Hilfsmittel im Bezug zum Bewohner, zu dokumentieren.
                      Es lassen sich per Drag and Drop auch externe Dokumente
                      und Korrespondenz hinzufügen.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="/assets/images/screenshots/l-besa/neu/Ereignis_Sturzprotokoll.png"
                      data-lightbox="l-besa-1"
                      data-title=""
                      data-actual-title="Ereignis/Sturzprotokoll"
                    >
                      <img src="/assets/images/screenshots/l-besa/neu/Ereignis_Sturzprotokoll.png" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Ereignis/Sturzprotokoll</h4>
                    <p className="fliesstext">
                      In WiCare|now Doc haben Sie die Möglichkeit, Ereignisse
                      wie bspw. ein Sturzereignis, komfortabel und unmittelbar
                      vor Ort auf dem Smartphone oder Tablet zu erfassen. Im
                      System bestehen bereits vordefinierte Erfassungsmasken zu
                      diversen Ereignissen. Diese können auf betriebliche
                      Gegebenheiten angepasst werden. Das System kann so
                      konfiguriert werden, dass bei einem Ereignis mit
                      Ausgangslage A ein anderer Inhalt angezeigt wird, als bei
                      einem Ereignis mit Ausgangslage B. Zudem besteht die
                      Möglichkeit, dass bei jeder Erfassung eine E-Mail an eine
                      vordefinierte Zieladresse gesendet wird. Somit kann die
                      Information automatisiert weitergeleitet werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                      <img
                        src="/assets/images/icons/Icon_Phone.jpg"
                        title="Phone"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>
          <section id="zusatz">
            <div className="wrapper">
              <div className="has-margin-bottom">
                <p className="fliesstextS is-color-grey text-centered margin-0-auto">
                  &nbsp; Bei den mit * gekennzeichneten Komponenten handelt es
                  sich um Advanced-Komponenten, d.h. diese sind kein Bestandteil
                  des Basispakets (zusätzliche Lizenz).
                </p>
              </div>
            </div>
          </section>
              <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>
          <footer className="mainFooter">
            <div className="wrapper">
              <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
                <ul>
                  <li>
                    <a href="/">Startseite</a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      WiCare|Doc-L
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-doc-l/mobil/index_rai">
                      mobil unterwegs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footerNotAnotherFlexboxButAGrid linklist">
                <div className="flex-item1">
                  <ul>
                    <li>
                      <a href="/dokumentationsloesungen/heime/">
                        <strong>Heime</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/spitaeler/">
                        <strong>Spitäler</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/rehabilitation/">
                        <strong>Rehabilitation</strong>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item2">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L BESA
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L RAI-NH
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-b/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -B
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-h/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -H
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-r/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -R
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item3">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-tacs/">
                        WiCare|now tacs<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-lep/">
                        WiCare|now LEP<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-le/">
                        WiCare|now LE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item4">
                  <ul>
                    <li>
                      <a href="/portrait/">
                        <strong>Über uns</strong>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/lehrlinge/">Lernende</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/partner/">Partner</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                    </li>
                    <li>
                      <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item5">
                  <ul>
                    <li>
                      <a href="/support/">
                        <strong>Support</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row copyright">
              <div className="wrapper">
                <div className="column">
                  © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
                </div>
                <div className="column has-padding-left">
                  <ul>
                    <li>
                      <a href="/datenschutz/">Datenschutzerklärung</a>
                    </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                  </ul>
                </div>
                <div className="column align-right">
                  <a href="/portrait/kontakt/" className="anfahrt">
                    <img
                      id="location-pointer"
                      src="/assets/images/icons/icon-anfahrt.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default DocLMobilUnterwegs;
